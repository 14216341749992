/* Start Contact */
export const validate_contact = (inputs) => {
    const errors = {};
    if (!inputs.name) {
      errors.name = 'Please enter your name';
    } 
	if (!inputs.email) {
      errors.email = 'Please enter email';
    }else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputs.email)
    ){
      errors.email = 'Invalid email';
    }
    
    return errors;
}
/* End Contact */