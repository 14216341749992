import React, { useEffect,useState } from 'react';
import loader from '../assets/loader.gif'; 
import API from '../Utils/Api';


function Antimoneylaundering() {

const [loading,setLoading] = useState(true);

const [input,setInput] = useState({page_name:'',content:''});

useEffect(() => {  
   document.title = `Privacy | FINTRION`;
   document.getElementById('bodywrap').className='money-main';
   
   /* Start get Anti money laundering */
		let formData = new FormData(); 
		formData.append('page_name', 'anti_money_laundering');
		API.post('get_page', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				
				setInput({
					page_name:response.data.data.title,
					content:response.data.data.content,
				});
				
			}   
		}).catch(error => {
			 setLoading(false);
		});  
	 /* End get Anti money laundering */
   
},[]) 

  return (
    <>
      <section className="money-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 money-box">              
			<div dangerouslySetInnerHTML={{__html: input.content}} />
            </div>
          </div>  
        </div>
      </section>
	  {loading && (
	   <div className="loadingClass">
			<img src={loader} alt="loader"/>
		</div>
	   )}  
    </>
  );
}

export default Antimoneylaundering;
