import React, { useState } from "react";
import { Link } from "react-router-dom";
import LogoImage from "../assets/navbar-logo.png";
import Bar from "../assets/bar.png";

function Navbar() {

  // State to control navbar collapse
  const [expanded, setExpanded] = useState(false);

  // Function to collapse the navbar when a link is clicked
  const handleNavCollapse = () => setExpanded(false);







  return (

      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid p-0 m-0">
          {/* Logo */}
          <Link className="navbar-brand d-lg-none" to="/">
            <img
              src={LogoImage}
              alt="logo"
              style={{ width: "100px", height: "auto" }}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setExpanded(expanded ? false : true)}
          >
            <img src={Bar} className="bar" alt="bar"/>
          </button>
          <div
            className={`collapse navbar-collapse justify-content-center ${
              expanded ? "show" : ""
            }`}
            id="navbarSupportedContent"
          >

            <ul className="navbar-nav mb-2 mb-lg-0">
              <div className="box1">
                <li className="nav-item menu1">
                  <Link className="nav-link active" onClick={handleNavCollapse} aria-current="page" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item menu2">
                  <Link className="nav-link active" onClick={handleNavCollapse} to="#">
                    Payment Methods
                  </Link>
                </li>
                <li className="nav-item menu3">
                  <Link className="nav-link active" onClick={handleNavCollapse} to="/services" >
                    Services
                  </Link>
                </li>
              </div>
              <div className="box2">
                {/* Logo */}
                <Link className="navbar-brand d-lg-block d-none menu4"  to="/">
                  <img
                    src={LogoImage}
                    alt="logo"
                    style={{ width: "100px", height: "auto" }}
                  />
                </Link>
              </div>
              <div className="box3">
                <li className="nav-item menu5">
                  <Link className="nav-link active" onClick={handleNavCollapse} to="#">
                    Geos
                  </Link>
                </li>
                <li className="nav-item menu6">
                  <Link className="nav-link active" onClick={handleNavCollapse} to="/contactus">
                    Integration
                  </Link>
                </li>
                <li className="nav-item menu7">
                  <Link className="nav-link active" onClick={handleNavCollapse} to="/pricing">
                    Pricing
                  </Link>
                </li>
                <li className="nav-item menu8">
                  <Link className="nav-link active" onClick={handleNavCollapse} to="/contactus">
                    Contact Us
                  </Link>
                </li>              
              </div>
            </ul>
          </div>
        </div>
      </nav>

  );
}

export default Navbar;
