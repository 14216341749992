import React, { useEffect,useState } from 'react';
import { Link } from "react-router-dom";
import True from "../assets/check.png";
import loader from '../assets/loader.gif'; 
import API from '../Utils/Api';

function Pricing() {
const [loading,setLoading] = useState(true);
const [pricing,setPricing] = useState([]);

useEffect(() => {  
   document.title = `PRICING | FINTRION`;
   document.getElementById('bodywrap').className='pricing-main';
   
   /* Start get pricing */
		API.get('pricing')
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				setPricing(response.data.data);
			}   
		}).catch(error => {
			 setLoading(false);
		});  
	 /* End get pricing */
   
},[])   

  return (
    <>
      {/* Pricing Button  Container*/}
      <section className="price-bg price-top">
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <button className="btn pricing-btn">
                Pricing
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Standard and Customized Container */}
      <section className="price-bg price-bottom">
        <div className="container pricing-cards">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
              <div className="row">
				{pricing && pricing.map((val,index) => {
					return(
                <div className="col-lg-6 col-12 pricing-box">
                  <div className="pricing-card">
                    <div>
					{(() => {
							if (index % 2 === 0 ) {
							  return (	
								<h1>{val.name}</h1>
							)
							}else{
							  return ( 
							  <h1 className="pricing-card2-h1">{val.name}</h1>
							 )
							}															
						  })()}
                    </div>

                    <div className="pricing-para">

                      <p className="">
					  {val.sub_title}
                      </p>
                    </div>
			
                    {val.description &&  val.description.map((val1,index1) => {
							return (<div className="row mx-0 mb-3">
                      <div className="col-auto px-0">
                        <img src={True} alt="TrueLogo" className="" />
                      </div>
                      <div className="col">
                        <p className="">
						{val1.description}
                        </p>                        
                      </div>                        
                    </div>)
							})}	
                    <div className="">
                    <Link to="/contactus">
                      <button className="btn pricing-card-btn">
                        Get Pricing
                      </button>
                      </Link>
                    </div>
                  </div>
                </div>
				)
					})}
              </div>
            </div>
          </div>
        </div>
      </section>
	  {loading && (
	   <div className="loadingClass">
			<img src={loader} alt="loader"/>
		</div>
	   )}  
    </>
  );
}
export default Pricing;