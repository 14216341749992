import React, { useEffect,useState } from 'react';
import FooterLogo from "../assets/footer-logo.png";
import FooterMail from "../assets/Mail.png";
import FooterTelegram from "../assets/Telegram.png";
import { Link } from "react-router-dom";
import API from '../Utils/Api';

function Footer() {

const [inputs,setInputs] = useState({app_name:'',app_email:'',app_contact_no:'',address:'',facebook:'',instagram:'',twitter:'',github:''})

useEffect(() => {  

   /* Start get general */
		API.get('general')
			.then(response => {
				
			if(response.data.status === 0){
				
			}else{
				setInputs({
					app_name:response.data.data.app_name,
					app_email:response.data.data.app_email,
					app_contact_no:response.data.data.app_contact_no,
					address:response.data.data.address,
					facebook:response.data.data.facebook,
					instagram:response.data.data.instagram,
					twitter:response.data.data.twitter,
					github:response.data.data.github
				});
			}   
		}).catch(error => {
			 
		});  
	 /* End get general */
   
},[])   	
	
	
  return (
    <>
      <footer className="bg-dark text-white">
        <div className="container">
          <div className="row text-center text-md-start ">
            <div className="col-md-4 mb-0">
              <img
                src={FooterLogo}
                alt="Cozmus Logo"
                className="mb-2 ftlogo"
              />
              <p className="ft-desc">
                Cozmus is a global fintech company providing secure and
                efficient payment processing solutions.
              </p>
              <p className="ft-copy">Copyright © 2024 Cozmus</p>
            </div>

            <div className="col-md-4 mb-0">
              <h5>Contact Us</h5>
              <ul className="list-unstyled ft-cnt">
                <li>
                  {/* <i className="bi bi-envelope"></i> info@Cozmus.com */}
                  <Link to={`mailto:${inputs.app_email}`}><img src={FooterMail} alt="" className="img w-auto" /> {inputs.app_email} </Link>
                </li>
                <li>
                  {/* <i className="bi bi-telegram"></i> @Cozmus */}
                 <Link to="#"> <img src={FooterTelegram} alt="" className="img w-auto" /> @cozmus </Link>
                </li>
                <li className="d-none">{inputs.address}</li>
              </ul>
            </div>

            <div className="col-md-4 mb-0">
              <h5>Terms And Condition</h5>
              <ul className="list-unstyled">
                <li>
                  <Link to="/anti-money-laundering" className="text-white">
                    Anti Money Laundering
                  </Link>
                </li>
                <li>
                  <Link to="/cookies-policy" className="text-white">
                    Cookies Policy
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy" className="text-white">
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-md-3 mb-0 d-none">
              <h5>Social Links</h5>
              <ul className="list-unstyled">
                <li>
                  <Link to={inputs.facebook} className="text-primary">
                    Facebook
                  </Link>
                </li>
                <li>
                  <Link to={inputs.instagram} className="text-danger instagram">
                    Instagram
                  </Link>
                </li>
                <li>
                  <Link to={inputs.twitter} className="text-info">
                    Twitter
                  </Link>
                </li>
                <li>
                  <Link to={inputs.github} className="text-success">
                    Github
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
