import React, { useEffect, useRef } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



import af from "../assets/af.png";
import as from "../assets/as.png";
import au from "../assets/au.png";
import eu from "../assets/eu.png";
import na from "../assets/na.png";
import sa from "../assets/sa.png";

import dot from "../assets/dot.png";






function Test() {

useEffect(() => {  
   document.title = `Test | FINTRION`;
   document.getElementById('bodywrap').className='home-main';
},[])   


  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,              // Enable autoplay
    autoplaySpeed: 3000,         // Set the speed for autoplay (in milliseconds)
    centerMode: true,            // Enable center mode
    centerPadding: '60px',       // Space around the centered item
  };

  const handleSlideClick = (index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  const slides = [
    {
      id: 1,
      content: 'Africa',
      image: af,
    },
    {
      id: 2,
      content: 'Asia',
      image: as,
    },
    {
      id: 3,
      content: 'Australia',
      image: au,
    },
    {
      id: 4,
      content: 'Europe',
      image: eu,
    },
    {
      id: 5,
      content: 'North America',
      image: na,
    },
    {
      id: 6,
      content: 'South America',
      image: sa,
    },        
  ];



  return (
    <>

      {/* Map Container Pending */}
      <section className="home-slide3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">



      <Slider ref={sliderRef} {...settings}>
        {slides.map((slide, index) => (
          <div key={slide.id} onClick={() => handleSlideClick(index)}>
              <div className="slbox">
                <div>
                  <img src={slide.image} alt="..." className="slimg" />              
                  <div className="slborder"><img src={dot} alt="..." className="sldot" /> </div> 
                  <h3>{slide.content}</h3>
                </div>
              </div>
          </div>
        ))}
      </Slider>
      








            
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Test;
