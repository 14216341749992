import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./css/style.css";
import "./css/mobile.css";
import "./css/font.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Pricing from "./pages/Pricing";
import ContactUs from "./pages/ContactUs";
import Services from "./pages/Services";
import Test from "./pages/Test";
import Privacy from "./pages/Privacy";
import Cookiespolicy from "./pages/Cookiespolicy";
import Antimoneylaundering from "./pages/Antimoneylaundering";

function App() {
  return (
    <>
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/test" element={<Test />} />
		<Route path="/privacy-policy" element={<Privacy />} />
		<Route path="/cookies-policy" element={<Cookiespolicy />} />
		<Route path="/anti-money-laundering" element={<Antimoneylaundering />} />
      </Routes>
      <Footer />
    </Router>
    </>
  );
}

export default App;
