import React, { useEffect, useState,useCallback } from 'react';
import loader from '../assets/loader.gif'; 
import API from '../Utils/Api';



function Services() {
const [loading,setLoading] = useState(true);
const [service,setService] = useState([]);
const [totalDivs,setTotalDivs] = useState(0);

useEffect(() => {  
   document.title = `SERVICES | FINTRION`;
   document.getElementById('bodywrap').className='service-main';
   
   /* Start get service */
		API.get('services')
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				setService(response.data.data);
				setTotalDivs(response.data.data.length);
			}   
		}).catch(error => {
			 setLoading(false);
		});  
	 /* End get service */
   
},[]) 
    const [currentDivIndex, setCurrentDivIndex] = useState(0);
    
     // Use useCallback to memoize handleScroll
  const handleScroll = useCallback(() => {
    const scrollTop = window.scrollY;
    const windowHeight = 600; // Fixed height of the parent div
    const newDivIndex = Math.floor(scrollTop / windowHeight);

    if (newDivIndex !== currentDivIndex && newDivIndex < totalDivs) {
      setCurrentDivIndex(newDivIndex);
    }
  }, [currentDivIndex, totalDivs]); // Include dependencies used within handleScroll

  useEffect(() => {
    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    return () => {
      // Cleanup the event listener on unmount
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]); // Now we include handleScroll in the dependency array


  return (
    <>
    <section className="serbox">
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1 col-lg-10 offset-lg-1 col-xl-10 offset-xl-1 col-xxl-10 offset-xxl-1 serwrap">
            <div className="parent">
                <div className="cont" style={{ transform: `translateY(${-currentDivIndex * 600}px)` }}>
                    {service && service.map((val,index) => {
						return(
							<div
								key={index}
								id={`div${index + 1}`} // Assign unique IDs
								className="scroll-div"
								style={{ opacity: index === currentDivIndex ? 1 : 0 }}
							>
								{(() => {
							if (index % 2 === 0 ) {
							  return (<div className="row section" id={`section${index + 1}`}>
								  <div className="col-md-6 col-12">
									  <img
										src={val.icon}
										alt="icon"
										className="sericn img-fluid"                    
									  />
									  <h3 className="sertitle">{val.name}</h3>
									  <div className="sercont">
										<p>{val.description}</p>
									  </div>
									  <button className="btn serbtn">Start Automating Your Payments Today!</button>
								  </div>
								  <div className="col-md-6 col-12">
									  <img
										src={val.image}
										alt="icon"
										className="serimg img-fluid"                    
									  />
								  </div>
								</div>)
							}else{
							  return ( <div className="row section" id={`section${index + 1}`}>
								  <div className="col-md-6 col-12 ord2">
									  <img
										src={val.icon}
										alt="icon"
										className="serimg img-fluid"                    
									  />
								  </div>
								  <div className="col-md-6 col-12">
									<img
									  src={val.image}
									  alt="icon"
									  className="sericn img-fluid"                    
									/>
									<h3 className="sertitle">{val.name}</h3>
									<div className="sercont">
									  <p>{val.description}</p>
									</div>
									<button className="btn serbtn">Launch Your Branded Gateway Now!</button>
								   
								  </div>
								</div>)
							}															
						  })()}
							</div>
                    )
					})} 
                </div>
                <div className="dotbox">
                    {service && service.map((val,index) => {
						return(
                        <div
                            key={index}                          
                            className={"dot " + (index === currentDivIndex ? 'active' : '')}
                        >
                        </div>
                    )
					})} 
                </div>
            </div>

			{service && service.map((val,index) => {
						return(
						<div className="row serind">
						  {(() => {
							if (index % 2 === 0 ) {
							  return ( <><div className="col-md-6 col-12">

							  <img
								src={val.icon}
								alt="icon"
								className="sericn img-fluid"                    
							  />
							  <h3 className="sertitle">{val.name} </h3>
							  <div className="sercont">
								<p>{val.description}</p>
							  </div>
							  <button className="btn serbtn">Start Automating Your Payments Today!</button>
						  </div>
						  <div className="col-md-6 col-12">
							  <img
								src={val.image}
								alt="icon"
								className="serimg img-fluid"                    
							  />
						  </div>
						  </>
						  )
							}else{
							  return (<> <div className="col-md-6 col-12 ord2">
							  <img
								src={val.icon}
								alt="icon"
								className="serimg img-fluid"                    
							  />
						  
						  </div>
						  <div className="col-md-6 col-12">

							<img
							  src={val.image}
							  alt="icon"
							  className="sericn img-fluid"                    
							/>
							<h3 className="sertitle">{val.name} </h3>
							<div className="sercont">
							  <p>{val.description}</p>
							</div>
							<button className="btn serbtn">Launch Your Branded Gateway Now!</button>
						   
						  </div></>)
							}															
						  })()}
						 </div>
						
					)
					})} 

            


          </div>
        </div>  
      </div>
    </section>
	{loading && (
	   <div className="loadingClass">
			<img src={loader} alt="loader"/>
		</div>
	   )}  
    </>
  );
}

export default Services;
